import React from 'react';

import Modal from '../../components/UserUpdated/Modal';
import Table from '../../components/UserUpdated/Table';
import Page from '../../layout/DefaultManager';

export default function User() {
  return (
    <Page
      path="/profiles"
      title="Usuários Alterados"
      subTitle="Gerenciador de Cadastros de Usuários alterados"
      description="Cadastros de Usuários que solicitaram alterações em seus perfils"
      selectedKey="9"
      hasActionCreate={false}
      Table={Table}
      ModalForm={Modal}
    />
  );
}
