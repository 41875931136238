// import api from './api';
export const TOKEN_KEY = '@Token';
export const ID_KEY = '@authorization_id';

export const isAuthenticated = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    const id = localStorage.getItem(ID_KEY);

    if (!token || !id) {
      return false;
    }

    /* const user = await api.get(`/users/${id}`)

    console.log(user)
    if(!user){
      return false;
    } */

    return true;
  } catch (error) {
    return false;
  }
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getIdKey = () => localStorage.getItem(ID_KEY);

export const setIdKey = (id) => {
  localStorage.setItem(ID_KEY, id);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(ID_KEY);
};
