import React from 'react';

import Modal from '../../components/Adm/Modal';
import Table from '../../components/Adm/Table';
import Page from '../../layout/DefaultManager';

export default function Adm() {
  return (
    <Page
      path="/adm"
      title="Administradores"
      subTitle="Gerenciador de Administradores"
      // description="Descricao para gerenciador"
      selectedKey="1"
      Table={Table}
      ModalForm={Modal}
    />
  );
}
