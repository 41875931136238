import React from 'react';

import Modal from '../../components/Events/Modal';
import Table from '../../components/Events/Table';
import Page from '../../layout/DefaultManager';

export default function Events() {
  return (
    <Page
      path="/events"
      title="Eventos"
      subTitle="Gerenciador de Eventos"
      // description="Descricao para gerenciador"
      selectedKey="6"
      Table={Table}
      ModalForm={Modal}
    />
  );
}
