import React from 'react';

import * as Yup from 'yup';

import Checkbox from '../Form/Checkbox';
import Input from '../Form/Input';
import Form from '../Modal/ModalForm';

export default function BusinessTeamModal(props) {
  const schemaValidation = Yup.object().shape({
    // thumbnail: Yup.mixed().required('Selecione uma imagem'),
    name: Yup.string().required('O nome é obrigatório'),
  });

  return (
    <Form
      title="Business Team"
      schemaValidation={schemaValidation}
      {...props}
    >
      <Input
        name="name"
        placeholder="Nome"
      />
      <Checkbox
        name="status"
        label="Ativo"
        hidden={!props.id}
      />
    </Form>
  );
}
