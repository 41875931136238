import React from 'react';

import Table from '../Table';
import ColumnSearch from '../Table/Filter';

function EventTable(props) {
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Nome', 'name'),
    },
    {
      title: 'Tema',
      dataIndex: 'thema',
      key: 'thema',
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Tema', 'thema'),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Descrição', 'description'),
    },
  ];

  return (
    <Table
      columns={columns}
      {...props}
    />
  );
}

export default EventTable;
