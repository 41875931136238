import React from 'react';

import { DeleteTwoTone, DownloadOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

import Table from '../Table';
import ColumnSearch from '../Table/Filter';
import { Action } from '../Table/styles';

function DocumentTable(props) {
  const columns = [
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Título', 'title'),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Descrição', 'description'),
    },
    {
      title: 'Nome do Arquivo',
      dataIndex: 'file',
      key: 'file',
      ellipsis: true,
    },
  ];

  return (
    <Table
      columns={columns}
      actions={{
        title: 'Ações',
        key: 'action',
        width: 150,
        render: (text, record) => (
          <Space size="middle">
            <Tooltip placement="top" title="Download">
              <a href={record.file_url}>
                <DownloadOutlined />
              </a>
            </Tooltip>
            <Tooltip placement="top" title="Excluir">
              <Action onClick={() => props.onDelete(record.id || record._id)}>
                <DeleteTwoTone twoToneColor="#f00" />
              </Action>
            </Tooltip>
          </Space>
        ),
      }}
      {...props}
    />
  );
}

export default DocumentTable;
