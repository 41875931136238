import React from 'react';

import * as Yup from 'yup';

import FileInput from '../Form/FileInput';
import Input from '../Form/Input';
import Form from '../Modal/ModalForm';

export default function DocumentModal(props) {
  const schemaValidation = Yup.object().shape({
    // thumbnail: Yup.mixed().required('Selecione uma imagem'),
    file: Yup.string().required('Selecione um Arquivo'),
    title: Yup.string().required('Informe um título para o Documento'),
  });

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('title', data.title);
    formData.append('description', data.description);
    return formData;
  };

  return (
    <Form
      title="Documentos"
      schemaValidation={schemaValidation}
      onFormData={handleFormData}
      {...props}
    >
      {
        !props.id && (
          <FileInput
            name="file"
          />
        )
      }
      <Input
        name="title"
        placeholder="Título"
      />
      <Input
        name="description"
        placeholder="Descrição"
      />
    </Form>
  );
}
