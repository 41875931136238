import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
`;

export const Container = styled.div`
`;

export const SearchContainer = styled.div`
  display: flex;
  padding: 5px;
`;

export const MsgError = styled.div`
  height: 25px;
  margin-left: 5px;
  color: #f00
`;
