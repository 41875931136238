import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import Adm from './pages/Adm';
import Advertisings from './pages/Advertisings';
import BusinessTeam from './pages/BusinessTeam';
import BusinessTeamCell from './pages/BusinessTeamCell';
import Documents from './pages/Documents';
import Events from './pages/Events';
import Galleries from './pages/Galleries';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Notices from './pages/Notices';
import SignIn from './pages/SignIn';
import Trainings from './pages/Trainings';
import User from './pages/User';
import UserByBusinessTeam from './pages/UserByBusinessTeam';
import UserByBusinessTeamCell from './pages/UserByBusinessTeamCell';
import UserUpdated from './pages/UserUpdated';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={SignIn} />
      <PrivateRoute path="/home" component={Home} />
      {/* Exemplo usuario utilizando tabela */}
      <PrivateRoute path="/administracao" component={Adm} />
      <PrivateRoute path="/usuarios" component={User} />
      <PrivateRoute path="/usuarios-alterados" component={UserUpdated} />
      <PrivateRoute path="/business-team" component={BusinessTeam} />
      <PrivateRoute path="/business-team-usuarios/:id/:name" component={UserByBusinessTeam} />
      <PrivateRoute path="/celulas" component={BusinessTeamCell} />
      <PrivateRoute path="/celulas-usuarios/:id/:name" component={UserByBusinessTeamCell} />
      <PrivateRoute path="/documentos" component={Documents} />
      <PrivateRoute path="/treinamentos" component={Trainings} />
      <PrivateRoute path="/eventos" component={Events} />
      <PrivateRoute path="/noticias" component={Notices} />
      <PrivateRoute path="/propagandas" component={Advertisings} />
      <PrivateRoute path="/galerias" component={Galleries} />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
