import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';

import { Form } from '@unform/web';
import { Button, notification } from 'antd';
import * as Yup from 'yup';

import api from '../../services/api';
import Select from '../Form/Select';
import Loading from '../Loading';
import Modal from '../Modal';

export default function BusinessTeamModal(props) {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [businessTeams, setBusinessTeams] = useState([]);
  const [businessTeamCells, setBusinessTeamCells] = useState([]);
  const formRef = useRef(null);

  const loadBusinessTeamCells = async (btId) => {
    try {
      if (btId) {
        setLoading(true);
        const response = await api.get(`/business-team-cell/business-team/${btId}`);
        const btcs = [];
        response.data.forEach((btc) => {
          if (btc.status) {
            btcs.push({
              value: btc._id,
              label: btc.name,
            });
          }
        });

        formRef.current.setFieldValue('businessTeamCell', null);
        setBusinessTeamCells(btcs);
        setLoading(false);
      }
    } catch (error) {
      if (error && error.response) {
        const { message } = error.response.data;
        if (message) {
          notification.error({
            message: 'Falha ao Carregar as células do Business Team',
            description: message,
          });
          return;
        }
      }

      notification.error({
        message: 'Falha ao Carregar as células do Business Team',
        description: error,
      });
    }
  };

  useEffect(() => {
    if (props.id) {
      const load = async () => {
        setLoading(true);
        if (formRef && formRef.current) {
          formRef.current.setFieldValue('businessTeam', null);
          formRef.current.setFieldValue('businessTeamCell', null);
        }
        const response = await api.get(`solicitations/${props.id}`);
        const { data } = response;
        setUser(data);
        if (data && data.businessTeam) {
          const { _id, name } = data.businessTeam;
          formRef.current.setFieldValue('businessTeam', {
            value: _id,
            label: name,
          });

          if (_id && data.businessTeamCell) {
            formRef.current.setFieldValue('businessTeamCell', {
              value: data.businessTeamCell._id,
              label: data.businessTeamCell.name,
            });
          }
        }
        setLoading(false);
      };

      load();
    }
  }, [props.id]);

  useEffect(() => {
    const loadBusinessTeam = async () => {
      try {
        const response = await api.get('business-team');
        const bts = [];
        response.data.forEach((bt) => {
          if (bt.status) {
            bts.push({
              value: bt._id,
              label: bt.name,
            });
          }
        });
        setBusinessTeams(bts);
      } catch (error) {
        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notification.error({
              message: 'Falha ao Carregar Business Team',
              description: message,
            });
            return;
          }
        }

        notification.error({
          message: 'Falha ao Carregar Business Team',
          description: error,
        });
      }
    };

    loadBusinessTeam();
  }, []);

  const handleChangeBusinessTeam = useCallback((bt) => {
    const load = async () => {
      if (bt) {
        await loadBusinessTeamCells(bt.value);
      }
    };

    load();
  }, []);

  const handleSubmit = async (data, { reset }) => {
    try {
      user.businessTeam = data.businessTeam;
      user.businessTeamCell = data.businessTeamCell;
      // console.log(user);
      const response = await api.put(`solicitations/${props.id}`, user);

      notification.success({
        message: 'Business Team',
        description: 'Business Team atualizado com sucesso!!!',
      });

      /* formRef.current.setErrors({}); */
      if (props.onUpdateData) {
        props.onUpdateData(response.data);
      }
      reset();
      props.onClose();
    } catch (err) {
      // Validation failed
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        notification.error({
          message: 'Falha na Validação!!!',
          description: 'Falta preencher algumas informações',
        });
        // console.log(err);
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
          // console.log(error.message);
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Modal
      title="Business Team"
      {...props}
    >
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <Loading spinning={loading}>
          <Select
            label="Business Team"
            name="businessTeam"
            options={businessTeams}
            onChange={handleChangeBusinessTeam}
          />

          <Select
            label="Célula"
            name="businessTeamCell"
            options={businessTeamCells}
          />
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Loading>
      </Form>
    </Modal>
  );
}
