/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  notification,
  Modal,
  Space,
} from 'antd';

import api from '../../services/api';
import Page from '../DefaultPage';
import { ActionsContainer } from './styles';

export default function DefaultManager({
  path,
  title,
  subTitle,
  description,
  selectedKey,
  children,
  hasActionCreate = true,
  hasActionUpdate = true,
  hasActionDelete = true,
  List,
  Table,
  ModalForm,
}) {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [models, setModels] = useState([]);
  const [id, setId] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { confirm } = Modal;

  const handlerError = (error, titleError) => {
    if (error && error.response) {
      const { message } = error.response.data;
      if (message) {
        notification.error({
          message: titleError,
          description: message,
        });
        return;
      }
    }

    notification.error({
      message: titleError,
      description: error,
    });
  };

  useEffect(() => {
    const loadModels = async () => {
      try {
        setLoading(true);
        if (path) {
          const res = await api.get(path);
          setModels(res.data);
        }
        setLoading(false);
      } catch (error) {
        handlerError(error, 'Falha para carregar os dados');
      }
    };

    loadModels();
  }, [path]);

  const handleOpen = (modelId) => {
    setId(modelId);
    setShowModal(true);
  };

  const handleClose = () => {
    setId(null);
    setShowModal(false);
  };

  const handleCreate = async (data) => {
    try {
      setLoading(true);
      const res = await api.post(path, data);
      const { model, message } = res.data;
      setModels([model, ...models]);
      handleClose();
      notification.success({
        message: title,
        description: message || 'Cadastrado com sucesso!!!',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handlerError(error, 'Falha ao Cadastrar');
    }
  };

  const handleUpdate = async (data) => {
    try {
      setLoading(true);
      const res = await api.put(`${path}/${id}`, data);
      const { model, message } = res.data;
      setModels([model, ...models.filter((value) => ((value.id || value._id) !== id))]);
      handleClose();
      notification.success({
        message: title,
        description: message || 'Alterado com sucesso!!!',
      });
      setLoading(false);
    } catch (error) {
      handleClose();
      handlerError(error, 'Falha ao alterar');
    }
  };

  const handleUpdateData = async (data) => {
    if (data.model) {
      const { model } = data;
      setModels([
        model,
        ...models.filter(
          (value) => (value.id || value._id) !== (model.id || model._id),
        ),
      ]);
      return;
    }
    setModels([
      data,
      ...models.filter((value) => ((value.id || value._id) !== (data.id || data._id))),
    ]);
  };

  const handleUpdateDatas = async (datas) => {
    setModels(datas);
  };

  const handleDelete = async (modelId) => {
    try {
      confirm({
        icon: <DeleteOutlined style={{
          color: '#f00',
          fontSize: '1.5rem',
        }}
        />,
        content: <span>Tem certeza que deseja excluir?</span>,
        async onOk() {
          try {
            const res = await api.delete(`${path}/${modelId}`);
            setModels(models.filter((value) => ((value.id || value._id) !== modelId)));
            notification.success({
              message: title,
              description: res.data.message || 'Excluido com sucesso',
            });
          } catch (error) {
            handleClose();
            handlerError(error, 'Falha ao excluir');
          }
        },
        okText: 'Excluir',
        okType: 'danger',
        width: '50%',
        centered: true,
      });
    } catch (error) {
      handleClose();
      handlerError(error, 'Falha ao excluir');
    }
  };

  const handleDeleteSelected = async () => {
    try {
      confirm({
        icon: <DeleteOutlined style={{
          color: '#f00',
          fontSize: '1.5rem',
        }}
        />,
        content: (
          <span>
            {`${selectedRowKeys.length} registro(s) selecionado(s).
              Tem certeza que deseja excluir-lo(s)?`}
          </span>
        ),
        async onOk() {
          let success = 0;
          const results = [];
          const del = async (modelId) => {
            try {
              await api.delete(`${path}/${modelId}`);
              setModels((prevModels) => prevModels.filter(
                (value) => (value.id || value._id) !== modelId,
              ));
              setSelectedRowKeys((prevKeys) => prevKeys.filter((value) => value !== modelId));
              success += 1;
            } catch (error) {
              handlerError(error);
            }
          };
          selectedRowKeys.forEach((modelId) => {
            results.push(del(modelId));
          });
          await Promise.all(results);

          if (success > 0) {
            notification.success({
              message: title,
              description: `${success} Registro(s) excluido(s) com sucesso.`,
            });
          }
        },
        okText: 'Excluir',
        okType: 'danger',
        width: '50%',
        centered: true,
      });
    } catch (error) {
      handleClose();
      handlerError(error, 'Falha ao excluir');
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  return (
    <Page
      title={title}
      subTitle={subTitle}
      description={description}
      selectedKey={selectedKey}
    >
      {
        ModalForm
        && (
          <ActionsContainer>
            <Space>
              {
                hasActionCreate && (
                  <Button
                    type="primary"
                    onClick={() => handleOpen(null)}
                  >
                    Cadastrar
                  </Button>
                )
              }
              { Table && hasActionDelete && (
                <Button
                  type="danger"
                  onClick={() => handleDeleteSelected()}
                  disabled={!(selectedRowKeys.length)}
                >
                  Excluir
                </Button>
              )}
            </Space>
          </ActionsContainer>
        )
      }
      {children}
      {
        List
        && (
        <List
          list={models}
          loading={loading}
          hasActionDelete={hasActionDelete}
          hasActionUpdate={hasActionUpdate}
          onOpen={handleOpen}
          onDelete={handleDelete}
          onUpdateData={handleUpdateData}
        />
        )
      }
      {
        Table
        && (
        <Table
          data={models}
          loading={loading}
          selectedRowKeys={selectedRowKeys}
          hasActionDelete={hasActionDelete}
          hasActionUpdate={hasActionUpdate}
          onOpen={handleOpen}
          onDelete={handleDelete}
          onUpdateData={handleUpdateData}
          onUpdateDatas={handleUpdateDatas}
          onSelectChange={onSelectChange}
        />
        )
      }

      {
        ModalForm
        && (
          <ModalForm
            id={id}
            path={path}
            visible={showModal}
            loading={loading}
            onCreate={handleCreate}
            onUpdateData={handleUpdateData}
            onUpdateDatas={handleUpdateDatas}
            onUpdate={handleUpdate}
            onClose={handleClose}
          />
        )
      }
    </Page>
  );
}
