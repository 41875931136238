/* eslint-disable react/button-has-type */
import React from 'react';

import { LinkOutlined } from '@ant-design/icons';
import { Progress } from 'antd';

import {
  Container, FileInfo, FileResult, Preview,
} from './styles';

const FileList = ({ files, onDelete }) => (
  <Container>
    {files.map((uploadedFile) => (
      <li key={uploadedFile.id}>
        <FileInfo>
          <Preview src={uploadedFile.preview} />
          <div>
            <strong>{uploadedFile.name}</strong>
            <span>
              {uploadedFile.readableSize}
              {' '}
              {uploadedFile.errorDetail && <small>{uploadedFile.errorDetail}</small>}
              {!!uploadedFile.uploaded && (
                <button onClick={() => onDelete(uploadedFile.id)}>
                  Excluir
                </button>
              )}
            </span>
          </div>
        </FileInfo>

        <FileResult>
          <div>
            {
            uploadedFile.url
              && (
              <a
                href={uploadedFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkOutlined style={{
                  fontSize: '18px',
                }}
                />
              </a>
              )
            }
          </div>

          <div>
            {
            uploadedFile.error
              && (
              <Progress
                type="circle"
                percent={uploadedFile.progress}
                status="exception"
                width={20}
              />
              )
            }
          </div>

          <div>
            {
            !uploadedFile.error
              && (
              <Progress
                type="circle"
                percent={uploadedFile.progress}
                width={20}
              />
              )
            }
          </div>
        </FileResult>
      </li>
    ))}
  </Container>
);

export default FileList;
