import React from 'react';

import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';

import Table from '../Table';
import ColumnSearch from '../Table/Filter';

function AdvertisingTable(props) {
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Nome', 'name'),
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Link', 'link'),
    },
    {
      title: 'Inicio',
      dataIndex: 'start',
      key: 'start',
      width: 96,
      align: 'center',
      render: (text, record) => (record.start ? (
        <Tooltip placement="top" title="Exibe no Início">
          <CheckCircleTwoTone twoToneColor="#389e0d" />
        </Tooltip>
      ) : (
        <CloseCircleTwoTone twoToneColor="#f5222d" />
      )),
    },
    {
      title: 'Parceiros',
      dataIndex: 'associate',
      key: 'associate',
      width: 96,
      align: 'center',
      render: (text, record) => (record.associate ? (
        <Tooltip placement="top" title="Exibe em Parceiros">
          <CheckCircleTwoTone twoToneColor="#389e0d" />
        </Tooltip>
      ) : (
        <CloseCircleTwoTone twoToneColor="#f5222d" />
      )),
    },
    {
      title: 'Login',
      dataIndex: 'signin',
      key: 'signin',
      width: 96,
      align: 'center',
      render: (text, record) => (record.signin ? (
        <Tooltip placement="top" title="Exibe no Login">
          <CheckCircleTwoTone twoToneColor="#389e0d" />
        </Tooltip>
      ) : (
        <CloseCircleTwoTone twoToneColor="#f5222d" />
      )),
    },
    {
      title: 'Timer',
      dataIndex: 'timer',
      key: 'timer',
      width: 96,
      align: 'center',
      render: (text, record) => (record.timer ? (
        <Tooltip placement="top" title="Exibe no Timer">
          <CheckCircleTwoTone twoToneColor="#389e0d" />
        </Tooltip>
      ) : (
        <CloseCircleTwoTone twoToneColor="#f5222d" />
      )),
    },
    {
      title: 'Home',
      dataIndex: 'home',
      key: 'home',
      width: 96,
      align: 'center',
      render: (text, record) => (record.home ? (
        <Tooltip placement="top" title="Exibe na Home">
          <CheckCircleTwoTone twoToneColor="#389e0d" />
        </Tooltip>
      ) : (
        <CloseCircleTwoTone twoToneColor="#f5222d" />
      )),
    },
  ];

  return (
    <Table
      columns={columns}
      {...props}
    />
  );
}

export default AdvertisingTable;
