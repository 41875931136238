import styled from 'styled-components';

export const Container = styled.ul`
  margin-top: 20px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      color: #999;
      margin-top: 5px;

      small{
        color: #f00;
      }

      button {
        border: 0;
        background: transparent;
        color: #e57878;
        font-weight: 600;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;

export const FileResult = styled.div`
  display: flex;
  align-items: center;
  div{
    margin: 2px
  }
`;

export const Preview = styled.img`
  height: 36px;
  border-radius: 5px;
  margin-right: 10px;
`;
