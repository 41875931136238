import React from 'react';

import { Layout } from 'antd';

import { Content } from './styles';

const { Footer } = Layout;

export default function FooterComponent() {
  return (
    <Footer style={{ background: '#fff', textAlign: 'center', padding: '20px' }}>
      <Content>
        <strong>Fulltime Networking</strong>
        <small>Conectando pessoas e negócios</small>
      </Content>
    </Footer>
  );
}
