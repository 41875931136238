import styled from 'styled-components';

export const BusinessTeamContainer = styled.div`
  min-height: 350px;
`;

export const Counter = styled.div`
  margin-bottom: 10px;
  font-size: 12pt;
  font-weight: 600;
`;
