import styled from 'styled-components';

export const Action = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const ActionLink = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;

  :hover {
    color: #40a9ff
  }
`;
