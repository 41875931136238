import React from 'react';

import { Loader } from './styles';

export default function Loading(props) {
  return (
    <Loader
      tip={props.message || 'Carregando...'}
      {...props}
    >
      {props.children}
    </Loader>
  );
}
