import React from 'react';

import { parseISO, format } from 'date-fns';
import * as Yup from 'yup';

import Checkbox from '../Form/Checkbox';
import ImageInput from '../Form/ImageInput';
import Input from '../Form/Input';
import InputMask from '../Form/InputMask';
import Textarea from '../Form/Textarea';
import Form from '../Modal/ModalForm';

export default function EventModal(props) {
  const handleSchemaValidation = async (data) => {
    const schemaValidation = Yup.object().shape({
      thumbnail: Yup.mixed().test({
        name: 'thumbnail',
        message: 'Selecione uma Imagem',
        test: () => props.id || (!props.id && !!data.thumbnail),
      }),
      name: Yup.string().required('Informe um nome para o Evento'),
      thema: Yup.string().required('Informe um tema para o Evento'),
      small_description: Yup.string().required('Informe uma breve descrição'),
      description: Yup.string().required('Informe uma descrição'),
      organizers: Yup.string().required('Informe os organizadores do evento'),
      date: Yup.string().required('Informe uma data para o Evento'),
      hour: Yup.string().required('Informe o horário do Evento'),
      address: Yup.string().required('Informe um endereço'),
      price: Yup.mixed().test({
        name: 'price',
        message: 'Informe o Valor do Evento',
        test: () => !data.isPaid || (data.isPaid && !!data.price),
      }),
    });

    const validate = await schemaValidation.validate(data, {
      abortEarly: false,
    });
    return validate;
  };

  const handleFieldValue = (formRef, data) => {
    if (data) {
      const { date } = data;
      formRef.current.setFieldValue('date', format(parseISO(date), 'dd/MM/yyyy'));
      // setIsPaid(data.isPaid);
    }
  };

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('thumbnail', data.thumbnail);
    formData.append('name', data.name);
    formData.append('thema', data.thema);
    formData.append('small_description', data.small_description);
    formData.append('description', data.description);
    formData.append('organizers', data.organizers);
    formData.append('objective', data.objective);
    formData.append('date', data.date);
    formData.append('hour', data.hour);
    formData.append('address', data.address);
    formData.append('isPaid', data.isPaid);
    formData.append('price', data.price);
    formData.append('obs', data.obs);
    return formData;
  };

  return (
    <Form
      title="Evento"
      onSchemaValidation={handleSchemaValidation}
      onFormData={handleFormData}
      onSetFieldValue={handleFieldValue}
      {...props}
    >
      <ImageInput
        name="thumbnail"
        label="Selecione uma imagem para capa do Evento"
      />
      <Input
        name="name"
        placeholder="Nome"
      />
      <Input
        name="thema"
        placeholder="Tema"
      />
      <Input
        name="small_description"
        placeholder="Breve descrição"
      />
      <Input
        name="description"
        placeholder="Descrição"
      />
      <Input
        name="organizers"
        placeholder="Organizadores"
      />
      <Input
        name="objective"
        placeholder="Objetivo"
      />
      <InputMask
        type="date"
        name="date"
        label="Data"
      />
      <InputMask
        name="hour"
        label="Horário"
        placeholder="HH:MM"
        format="##:##"
        mask={['H', 'H', 'M', 'M']}
      />
      <Input
        name="address"
        placeholder="Endereço"
      />
      <Checkbox
        name="isPaid"
        label="É pago"
      />
      <InputMask
        type="currency"
        name="price"
        placeholder="Valor do Evento"
      />
      <Textarea
        name="obs"
        placeholder="Observações"
      />
    </Form>
  );
}
