import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  HomeOutlined,
  DesktopOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
  NotificationOutlined,
  TeamOutlined,
  PictureOutlined,
  PicRightOutlined,
  CalendarOutlined,
  FileAddOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';

import { logout } from '../../services/auth';

const { Sider } = Layout;
// const { SubMenu } = Menu;

export default function MenuLeft({ selectedKey }) {
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();

  const handleCollapse = () => {
    setCollapsed((oldstate) => (!oldstate));
  };

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
      width="250px"
    >
      <Menu theme="dark" defaultSelectedKeys={[selectedKey]} mode="inline">
        <Menu.Item
          key="0"
          icon={<HomeOutlined />}
          onClick={() => {
            history.push('/home');
          }}
        >
          Home
        </Menu.Item>
        <Menu.Item
          key="1"
          icon={<DesktopOutlined />}
          onClick={() => {
            history.push('/administracao');
          }}
        >
          Área Administrativa
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<UserOutlined />}
          onClick={() => {
            history.push('/usuarios');
          }}
        >
          Gerenciar Usuários
        </Menu.Item>
        <Menu.Item
          key="9"
          icon={<UserSwitchOutlined />}
          onClick={() => {
            history.push('/usuarios-alterados');
          }}
        >
          Solicitações de Alteração no Cadastro
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<TeamOutlined />}
          onClick={() => {
            history.push('/business-team');
          }}
        >
          Business Team
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<UsergroupAddOutlined />}
          onClick={() => {
            history.push('/celulas');
          }}
        >
          Células
        </Menu.Item>
        <Menu.Item
          key="5"
          icon={<FileAddOutlined />}
          onClick={() => {
            history.push('/documentos');
          }}
        >
          Documentos
        </Menu.Item>
        <Menu.Item
          key="11"
          icon={<FileAddOutlined />}
          onClick={() => {
            history.push('/treinamentos');
          }}
        >
          Treinamentos
        </Menu.Item>
        <Menu.Item
          key="6"
          icon={<CalendarOutlined />}
          onClick={() => {
            history.push('/eventos');
          }}
        >
          Eventos
        </Menu.Item>
        <Menu.Item
          key="7"
          icon={<PicRightOutlined />}
          onClick={() => {
            history.push('/noticias');
          }}
        >
          Notícias
        </Menu.Item>
        <Menu.Item
          key="10"
          icon={<NotificationOutlined />}
          onClick={() => {
            history.push('/propagandas');
          }}
        >
          Propaganda
        </Menu.Item>
        <Menu.Item
          key="8"
          icon={<PictureOutlined />}
          onClick={() => {
            history.push('/galerias');
          }}
        >
          Galeria de Fotos
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} onClick={handleLogout}>
          Sair
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
