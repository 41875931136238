import React from 'react';

import { Tabs } from 'antd';
import * as Yup from 'yup';

import Input from '../Form/Input';
import Form from '../Modal/ModalForm';

export default function AdmModal(props) {
  const schemaValidation = Yup.object().shape({
    // thumbnail: Yup.mixed().required('Selecione uma imagem'),
    name: Yup.string().required('O nome é obrigatório'),
    email: Yup.string()
      .email('Informe um email válido')
      .required('O email é obrigatório'),
    password: Yup.string()
      .min(6, 'Informe pelo menos 6 caracteres')
      .required('A senha é obrigatória'),
  });

  return (
    <Form
      title="Administrador"
      schemaValidation={schemaValidation}
      {...props}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          tab="Login"
          key="1"
        >
          <Input
            name="name"
            placeholder="Nome"
          />
          <Input
            name="email"
            placeholder="Email"
          />
          {
            !props.id && (
              <Input
                type="password"
                name="password"
                placeholder="Senha"
                autoComplete="off"
              />
            )
          }
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Dados"
          key="2"
          forceRender
        >
          <Input
            name="rg"
            placeholder="RG"
          />
          <Input
            name="cpf"
            placeholder="CPF"
          />
          <Input
            name="celular"
            placeholder="Celular"
          />
          <Input
            name="data_nascimento"
            placeholder="Data de Nascimento"
          />
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
}
