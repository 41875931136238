import React from 'react';

import logoportal from '../../assets/logo-portal.png';
import Page from '../../layout/DefaultPage';
import { Container } from './styles';

export default function Home() {
  return (
    <Page
      title="Home"
      subTitle="Página Inicial"
      selectedKey="0"
    >
      <Container>
        <img src={logoportal} alt="Portal Fulltime" />
      </Container>
    </Page>
  );
}
