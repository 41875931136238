import React from 'react';

import * as Yup from 'yup';

import ImageInput from '../Form/ImageInput';
import Input from '../Form/Input';
import Textarea from '../Form/Textarea';
import Form from '../Modal/ModalForm';

export default function NoticeModal(props) {
  const handleSchemaValidation = async (data) => {
    const schemaValidation = Yup.object().shape({
      thumbnail: Yup.mixed().test({
        name: 'thumbnail',
        message: 'Selecione uma Imagem',
        test: () => props.id || (!props.id && !!data.thumbnail),
      }),
      title: Yup.string().required('Informe um título para a Notícia'),
      description: Yup.string().required('Informe uma descrição para a Notícia'),
      content: Yup.string().required('Qual o conteúdo da Notícia?'),
    });

    const validate = await schemaValidation.validate(data, {
      abortEarly: false,
    });
    return validate;
  };

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('thumbnail', data.thumbnail);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('content', data.content);
    return formData;
  };

  return (
    <Form
      title="Notícias"
      onSchemaValidation={handleSchemaValidation}
      onFormData={handleFormData}
      {...props}
    >
      <ImageInput
        name="thumbnail"
      />
      <Input
        name="title"
        placeholder="Título"
      />
      <Input
        name="description"
        placeholder="Descrição"
      />
      <Textarea
        name="content"
        placeholder="Conteúdo"
      />
    </Form>
  );
}
