import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import { format, parseISO } from 'date-fns';
// import { zonedTimeToUtc } from 'date-fns-tz';

import { MsgError } from '../styles';
import { Container, Input, Label } from './styles';

export default function InputMask({
  type = 'default',
  name,
  hidden,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName, defaultValue = '', registerField, error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue(ref) {
        ref.state.value = '';
        ref.state.numAsString = '';
      },
      getValue(ref) {
        const { value, numAsString } = ref.state;
        if (!value) return '';

        if (type === 'date') {
          const date = value.split('/');
          return parseISO(new Date(date[2], (parseInt(date[1], 10) - 1), date[0]).toISOString());
          /* return zonedTimeToUtc(
            parseISO(new Date(date[2], parseInt(date[1], 10) - 1, date[0])),
            "America/Sao_Paulo"
          ); */
        }
        return numAsString.replace(/[^0-9]+/g, '');
      },
      setValue(ref, value) {
        if (!value) {
          ref.state.numAsString = '';
          return;
        }

        if (type === 'date') {
          ref.state.numAsString = format(
            parseISO(value),
            'dd/MM/yyyy',
          ).replace(/[^0-9]+/g, '');
          return;
        }
        ref.state.numAsString = value.replace(/[^0-9]+/g, '');
      },
    });
  }, [fieldName, registerField, type]);

  return (
    <Container
      hidden={hidden}
    >
      {error && <MsgError>{error}</MsgError>}
      {inputRef && inputRef.current && inputRef.current.value && (
        <Label>{rest.label ? rest.label : rest.placeholder}</Label>
      )}
      {
        type === 'default' && (
          <Input
            ref={inputRef}
            defaultValue={defaultValue}
            {...rest}
          />
        )
      }
      {
        type === 'date' && (
          <Input
            ref={inputRef}
            defaultValue={defaultValue}
            format="##/##/####"
            placeholder="DD/MM/AAAA"
            mask={['D', 'D', 'M', 'M', 'A', 'A', 'A', 'A']}
            autoCorrect="off"
            {...rest}
          />
        )
      }
      {
        type === 'cnpj' && (
          <Input
            ref={inputRef}
            defaultValue={defaultValue}
            format="##.###.###/####-##"
            mask="_"
            autoCorrect="off"
            {...rest}
          />
        )
      }
      {
        type === 'cpf' && (
          <Input
            ref={inputRef}
            defaultValue={defaultValue}
            format="###.###.###-##"
            mask="_"
            autoCorrect="off"
            {...rest}
          />
        )
      }
      {
        type === 'cel-phone' && (
          <Input
            ref={inputRef}
            defaultValue={defaultValue}
            format="(##) #####-####"
            mask="_"
            autoCorrect="off"
            {...rest}
          />
        )
      }
      {
        type === 'phone' && (
          <Input
            ref={inputRef}
            defaultValue={defaultValue}
            format="(##) ####-####"
            mask="_"
            autoCorrect="off"
            {...rest}
          />
        )
      }
      {
        type === 'currency' && (
          <Input
            ref={inputRef}
            defaultValue={defaultValue}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            prefix="R$ "
            autoCorrect="off"
            autoComplete="off"
            {...rest}
          />
        )
      }
    </Container>
  );
}
