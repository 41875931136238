import NumberFormat from 'react-number-format';

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 10px 0;
`;

export const Input = styled(NumberFormat)`
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 2px;
`;

export const Label = styled.label`
  margin-left: 5px;
  font-size: 8pt;
  font-weight: 600;
  color: #333;
`;

export default Input;
