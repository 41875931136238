import React, { useState } from 'react';

import { DeleteTwoTone, EditTwoTone, PictureOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

import Photos from '../Photos';
import Table from '../Table';
import ColumnSearch from '../Table/Filter';
import { Action } from '../Table/styles';

function GalleryTable(props) {
  const [galleryId, setGalleryId] = useState(null);
  const [galleryTitle, setGalleryTitle] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Título', 'title'),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Descrição', 'description'),
    },
  ];

  const handleOpenPhotos = (id, title) => {
    setGalleryId(id);
    setGalleryTitle(title);
    setShowModal(true);
  };

  return (
    <>
      <Photos
        id={galleryId}
        title={galleryTitle}
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
      <Table
        columns={columns}
        actions={{
          title: 'Ações',
          key: 'action',
          width: 150,
          render: (text, record) => (
            <Space size="middle">
              <Tooltip placement="top" title="Alterar">
                <Action onClick={() => props.onOpen(record.id || record._id)}>
                  <EditTwoTone />
                </Action>
              </Tooltip>
              <Tooltip placement="top" title="Excluir">
                <Action onClick={() => props.onDelete(record.id || record._id)}>
                  <DeleteTwoTone twoToneColor="#f00" />
                </Action>
              </Tooltip>
              <Tooltip placement="top" title="Gerenciar Fotos">
                <Action onClick={() => handleOpenPhotos(record.id || record._id, record.title)}>
                  <PictureOutlined />
                </Action>
              </Tooltip>
            </Space>
          ),
        }}
        {...props}
      />
    </>
  );
}

export default GalleryTable;
