import React from 'react';

import * as Yup from 'yup';

import Input from '../Form/Input';
import Form from '../Modal/ModalForm';

export default function GalleryModal(props) {
  const schemaValidation = Yup.object().shape({
    title: Yup.string().required('Informe um título para a Galeria'),
    description: Yup.string().required('Informe uma descrição para a Galeria'),
  });

  return (
    <Form
      title="Galeria de Fotos"
      schemaValidation={schemaValidation}
      {...props}
    >
      <Input
        name="title"
        placeholder="Título"
      />
      <Input
        name="description"
        placeholder="Descrição"
      />
    </Form>
  );
}
