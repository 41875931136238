import React from 'react';

import Modal from '../../components/Galleries/Modal';
import Table from '../../components/Galleries/Table';
import Page from '../../layout/DefaultManager';

export default function Galleries() {
  return (
    <Page
      path="/galleries"
      title="Galeria de Fotos"
      subTitle="Gerenciador de Fotos"
      // description="Descricao para gerenciador"
      selectedKey="8"
      Table={Table}
      ModalForm={Modal}
    />
  );
}
