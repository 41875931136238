import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';

import {
  Button, Space, Tabs, notification,
} from 'antd';
import { parseISO, format } from 'date-fns';

import categories from '../../datas/categorias.json';
import api from '../../services/api';
import Loading from '../Loading';
import Modal from '../Modal';
import {
  DataContainer, Data, Updated, Label,
} from './styles';

export default function UserModal(props) {
  const [updated, setUpdated] = useState({});
  const [data, setData] = useState({});
  const [oldCategory, setOldCategory] = useState({});
  const [newCategory, setNewCategory] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.id) {
      const load = async () => {
        setLoading(true);
        const response = await api.get(`/profiles/${props.id}`);
        setData(response.data.solicitation);
        setUpdated(response.data);
        setOldCategory(categories.find(
          (c) => c.value === response.data.solicitation.activity_branch,
        ));
        setNewCategory(categories.find(
          (c) => c.value === response.data.activity_branch,
        ));
        setLoading(false);
      };

      load();
    }
  }, [props.id]);

  const handleApprove = async (id, aproved) => {
    try {
      const response = await api.put(`/approve-profile/${id}`, aproved);
      const { data: profile, message } = response.data;
      props.onUpdateData(profile);
      notification.success({
        message: 'Alteração de Cadastro',
        description: message,
      });
      props.onClose();
    } catch (error) {
      if (error && error.response) {
        const { message } = error.response.data;
        if (message) {
          notification.error({
            message: 'Alteração de Cadastro',
            description: message,
          });
          return;
        }
      }

      notification.error({
        message: 'Alteração de Cadastro',
        description: error,
      });
    }
  };

  return (
    <Modal
      title="Usuário"
      {...props}
    >
      <Loading spinning={loading}>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane
            tab="Empresa"
            key="1"
          >
            {/*
            contact_name: String,
            cnpj: String,
            cpf: String,
            fantasy_name: String,
            date_open: Date,
            activity_branch: String,
            owner_name1: String,
            owner_name2: String,
            owner_name3: String,
            owner_name4: String,
            owner_name5: String,
            small_description: String,
           */}
            <DataContainer>
              <Label>Nome Completo</Label>
              {data.contact_name !== updated.contact_name ? (
                <>
                  <Data>{`Atual: ${data.contact_name}`}</Data>
                  <Updated>{`Novo:  ${updated.contact_name}`}</Updated>
                </>
              ) : (
                <Data>{data.contact_name}</Data>
              )}
            </DataContainer>
            {
              data.cnpj ? (
                <DataContainer>
                  <Label>CNPJ</Label>
                  {data.cnpj !== updated.cnpj ? (
                    <>
                      <Data>
                        Atual:
                        {' '}
                        <NumberFormat
                          displayType="text"
                          value={data.cnpj}
                          format="##.###.###/####-##"
                        />
                      </Data>
                      <Updated>
                        Novo:
                        {'  '}
                        <NumberFormat
                          displayType="text"
                          value={updated.cnpj}
                          format="##.###.###/####-##"
                        />
                      </Updated>
                    </>
                  ) : (
                    <Data>
                      <NumberFormat
                        displayType="text"
                        value={data.cnpj}
                        format="##.###.###/####-##"
                      />
                    </Data>
                  )}
                </DataContainer>
              ) : (
                <DataContainer>
                  <Label>CPF</Label>
                  {data.cpf !== updated.cpf ? (
                    <>
                      <Data>
                        Atual:
                        {' '}
                        <NumberFormat
                          displayType="text"
                          value={data.cpf}
                          format="###.###.###-##"
                        />
                      </Data>
                      <Updated>
                        Novo:
                        {'  '}
                        <NumberFormat
                          displayType="text"
                          value={updated.cpf}
                          format="###.###.###-##"
                        />
                      </Updated>
                    </>
                  ) : (
                    <Data>
                      <NumberFormat
                        displayType="text"
                        value={data.cpf}
                        format="###.###.###-##"
                      />
                    </Data>
                  )}
                </DataContainer>
              )
            }
            <DataContainer>
              <Label>Nome Fantasia</Label>
              {data.fantasy_name !== updated.fantasy_name ? (
                <>
                  <Data>{`Atual: ${data.fantasy_name}`}</Data>
                  <Updated>{`Novo:  ${updated.fantasy_name}`}</Updated>
                </>
              ) : (
                <Data>{data.fantasy_name}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Inicio das Atividades</Label>
              {data.date_open !== updated.date_open ? (
                <>
                  {
                    !!data.date_open && (
                      <Data>{`Atual: ${format(parseISO(data.date_open), 'dd/MM/yyyy')}`}</Data>
                    )
                  }
                  {
                    !!updated.date_open && (
                      <Updated>{`Novo: ${format(parseISO(updated.date_open), 'dd/MM/yyyy')}`}</Updated>
                    )
                  }
                </>
              ) : (
                !!data.date_open && (
                  <Data>{format(parseISO(data.date_open), 'dd/MM/yyyy')}</Data>
                )
              )}
            </DataContainer>

            <DataContainer>
              <Label>Ramo de Atividade</Label>
              {oldCategory && oldCategory.value !== newCategory.value ? (
                <>
                  <Data>{`Atual: ${oldCategory.label}`}</Data>
                  <Updated>{`Novo:  ${newCategory.label}`}</Updated>
                </>
              ) : (
                <Data>{oldCategory.label}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Proprietário 1</Label>
              {data.owner_name1 !== updated.owner_name1 ? (
                <>
                  <Data>{`Atual: ${data.owner_name1}`}</Data>
                  <Updated>{`Novo:  ${updated.owner_name1}`}</Updated>
                </>
              ) : (
                <Data>{data.owner_name1}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Proprietário 2</Label>
              {data.owner_name2 !== updated.owner_name2 ? (
                <>
                  <Data>{`Atual: ${data.owner_name2}`}</Data>
                  <Updated>{`Novo:  ${updated.owner_name2}`}</Updated>
                </>
              ) : (
                <Data>{data.owner_name2}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Proprietário 3</Label>
              {data.owner_name3 !== updated.owner_name3 ? (
                <>
                  <Data>{`Atual: ${data.owner_name3}`}</Data>
                  <Updated>{`Novo:  ${updated.owner_name3}`}</Updated>
                </>
              ) : (
                <Data>{data.owner_name3}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Proprietário 4</Label>
              {data.owner_name4 !== updated.owner_name4 ? (
                <>
                  <Data>{`Atual: ${data.owner_name4}`}</Data>
                  <Updated>{`Novo:  ${updated.owner_name4}`}</Updated>
                </>
              ) : (
                <Data>{data.owner_name4}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Proprietário 5</Label>
              {data.owner_name5 !== updated.owner_name5 ? (
                <>
                  <Data>{`Atual: ${data.owner_name5}`}</Data>
                  <Updated>{`Novo:  ${updated.owner_name5}`}</Updated>
                </>
              ) : (
                <Data>{data.owner_name5}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Descrição</Label>
              {data.small_description !== updated.small_description ? (
                <>
                  <Data>{`Atual: ${data.small_description}`}</Data>
                  <Updated>{`Novo:  ${updated.small_description}`}</Updated>
                </>
              ) : (
                <Data>{data.small_description}</Data>
              )}
            </DataContainer>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Endereço"
            key="2"
            forceRender
          >
            {/*
            zip_code: String,
            street: String,
            number: String,
            complement: String,
            neighborhood: String,
            city: String,
            state: String,
          */}
            <DataContainer>
              <Label>CEP</Label>
              {data.zip_code !== updated.zip_code ? (
                <>
                  <Data>
                    Atual:
                    {' '}
                    <NumberFormat
                      displayType="text"
                      value={data.zip_code}
                      format="##.###-###"
                    />
                  </Data>
                  <Updated>
                    Novo:
                    {'  '}
                    <NumberFormat
                      displayType="text"
                      value={updated.zip_code}
                      format="##.###-###"
                    />
                  </Updated>
                </>
              ) : (
                <Data>
                  <NumberFormat
                    displayType="text"
                    value={data.zip_code}
                    format="##.###-###"
                  />
                </Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Logradouro</Label>
              {data.street !== updated.street ? (
                <>
                  <Data>{`Atual: ${data.street}`}</Data>
                  <Updated>{`Novo:  ${updated.street}`}</Updated>
                </>
              ) : (
                <Data>{data.street}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Número</Label>
              {data.number !== updated.number ? (
                <>
                  <Data>{`Atual: ${data.number}`}</Data>
                  <Updated>{`Novo:  ${updated.number}`}</Updated>
                </>
              ) : (
                <Data>{data.number}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Complemento</Label>
              {data.complement !== updated.complement ? (
                <>
                  <Data>{`Atual: ${data.complement}`}</Data>
                  <Updated>{`Novo:  ${updated.complement}`}</Updated>
                </>
              ) : (
                <Data>{data.complement}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Bairro</Label>
              {data.neighborhood !== updated.neighborhood ? (
                <>
                  <Data>{`Atual: ${data.neighborhood}`}</Data>
                  <Updated>{`Novo:  ${updated.neighborhood}`}</Updated>
                </>
              ) : (
                <Data>{data.neighborhood}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Cidade</Label>
              {data.city !== updated.city ? (
                <>
                  <Data>{`Atual: ${data.city}`}</Data>
                  <Updated>{`Novo:  ${updated.city}`}</Updated>
                </>
              ) : (
                <Data>{data.city}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Estado</Label>
              {data.state !== updated.state ? (
                <>
                  <Data>{`Atual: ${data.state}`}</Data>
                  <Updated>{`Novo:  ${updated.state}`}</Updated>
                </>
              ) : (
                <Data>{data.state}</Data>
              )}
            </DataContainer>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Contatos"
            key="3"
            forceRender
          >
            {/**
            whatsapp: String,
            phone: String,
            other_phones: String,
            company_email: String,
            website: String,
            facebook: String,
            instagram: String,
           */}
            <DataContainer>
              <Label>WhatsApp</Label>
              {data.whatsapp !== updated.whatsapp ? (
                <>
                  <Data>{`Atual: ${data.whatsapp}`}</Data>
                  <Updated>{`Novo:  ${updated.whatsapp}`}</Updated>
                </>
              ) : (
                <Data>{data.whatsapp}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Telefone</Label>
              {data.phone !== updated.phone ? (
                <>
                  <Data>{`Atual: ${data.phone}`}</Data>
                  <Updated>{`Novo:  ${updated.phone}`}</Updated>
                </>
              ) : (
                <Data>{data.phone}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Outro Telefone</Label>
              {data.other_phones !== updated.other_phones ? (
                <>
                  <Data>{`Atual: ${data.other_phones}`}</Data>
                  <Updated>{`Novo:  ${updated.other_phones}`}</Updated>
                </>
              ) : (
                <Data>{data.other_phones}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Email Empresarial</Label>
              {data.company_email !== updated.company_email ? (
                <>
                  <Data>{`Atual: ${data.company_email}`}</Data>
                  <Updated>{`Novo:  ${updated.company_email}`}</Updated>
                </>
              ) : (
                <Data>{data.company_email}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Site</Label>
              {data.website !== updated.website ? (
                <>
                  <Data>{`Atual: ${data.website}`}</Data>
                  <Updated>{`Novo:  ${updated.website}`}</Updated>
                </>
              ) : (
                <Data>{data.website}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Facebook</Label>
              {data.facebook !== updated.facebook ? (
                <>
                  <Data>{`Atual: ${data.facebook}`}</Data>
                  <Updated>{`Novo:  ${updated.facebook}`}</Updated>
                </>
              ) : (
                <Data>{data.facebook}</Data>
              )}
            </DataContainer>

            <DataContainer>
              <Label>Instagram</Label>
              {data.instagram !== updated.instagram ? (
                <>
                  <Data>{`Atual: ${data.instagram}`}</Data>
                  <Updated>{`Novo:  ${updated.instagram}`}</Updated>
                </>
              ) : (
                <Data>{data.instagram}</Data>
              )}
            </DataContainer>
          </Tabs.TabPane>
        </Tabs>
        <Space>
          {
            !updated.aproved && (
              <Button
                type="primary"
                onClick={() => handleApprove(props.id, {
                  aproved: true,
                })}
              >
                Aprovar
              </Button>
            )
          }
          <Button onClick={props.onClose}>
            Fechar
          </Button>
        </Space>
      </Loading>
    </Modal>
  );
}
