import React from 'react';

import Modal from '../../components/User/Modal';
import Table from '../../components/User/Table';
import Page from '../../layout/DefaultManager';

export default function User() {
  return (
    <Page
      path="/solicitations"
      title="Usuários"
      subTitle="Gerenciador de Usuários"
      // description="Um exemplo de Gerenciador de Usuário utilizando tabela"
      selectedKey="2"
      hasActionCreate={false}
      Table={Table}
      ModalForm={Modal}
    />
  );
}
