import React, { useEffect, useRef } from 'react';

import { Form } from '@unform/web';
import { Button, notification } from 'antd';
import * as Yup from 'yup';


import loginImg from '../../assets/login.png';
import Input from '../../components/Form/Input';
import api from '../../services/api';
import {
  getToken, setToken, setIdKey, getIdKey,
} from '../../services/auth';
import { LoginContainer, Container } from './styles';

export default function SignIn({ history }) {
  useEffect(() => {
    const verifyUser = () => {
      const token = getToken();
      const id = getIdKey();

      if (token && id) {
        history.push('/home');
      }
    };
    verifyUser();
  });

  const formRef = useRef(null);

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required('O email é obrigatório'),
        password: Yup.string().required('A senha é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // validation passed
      const res = await api.post('/authenticate', null, {
        headers: data,
      });
      const { token, user } = res.data;

      setToken(token);
      setIdKey(user.id || user._id);

      notification.success({
        message: 'Login',
        description: 'Login realizado com sucesso',
      });

      setTimeout(() => {
        history.push('/home');
      }, 3000);
    } catch (err) {
      // Validation failed
      const validationErrors = {};
      formRef.current.setErrors({});
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
        return;
      }

      if (err && err.response && err.response.data) {
        const { error } = err.response.data;
        notification.error({
          message: 'Falha ao realizar Login',
          description: error,
        });
      }
    }
  };

  return (
    <>
      <Container>
        <LoginContainer>
          <img src={loginImg} alt="login" />
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Input
              type="email"
              name="email"
              placeholder="Endereço de e-mail"
            />
            <Input
              type="password"
              name="password"
              placeholder="Senha"
              autoComplete="off"
            />
            <Button
              htmlType="submit"
              size="large"
              block
            >
              Entrar
            </Button>
          </Form>
        </LoginContainer>
      </Container>
    </>
  );
}
