import React from 'react';

import Modal from '../../components/BusinessTeamCell/Modal';
import Table from '../../components/BusinessTeamCell/Table';
import Page from '../../layout/DefaultManager';

export default function BusinessTeamCell() {
  return (
    <Page
      path="/business-team-cell"
      title="Células do Business Team"
      subTitle="Gerenciador de Células do Business Team"
      // description="Descricao para gerenciador"
      selectedKey="4"
      Table={Table}
      ModalForm={Modal}
    />
  );
}
