import React from 'react';

import Routes from './routes';
import GlobalStyle from './styles/global';

import 'antd/dist/antd.css';

const App = () => (
  <>
    <Routes />
    <GlobalStyle />
  </>
);

export default App;
