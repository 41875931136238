import React from 'react';

import Modal from '../../components/BusinessTeam/Modal';
import Table from '../../components/BusinessTeam/Table';
import Page from '../../layout/DefaultManager';

export default function BusinessTeam() {
  return (
    <Page
      path="/business-team"
      title="Business Team"
      subTitle="Gerenciador de Business Team"
      // description="Descricao para gerenciador"
      selectedKey="3"
      Table={Table}
      ModalForm={Modal}
    />
  );
}
