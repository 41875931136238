import React, { useState, useCallback } from 'react';

import { EditTwoTone, DeleteTwoTone, LockOutlined } from '@ant-design/icons';
import { Space, Tooltip, notification } from 'antd';

import api from '../../services/api';
import Modal from '../Password';
import Table from '../Table';
import ColumnSearch from '../Table/Filter';
import { Action } from '../Table/styles';

function AdmTable(props) {
  const [id, setId] = useState();
  const [showModal, setShowModal] = useState();

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Nome', 'name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Email', 'email'),
    },
  ];

  const handleUpdate = useCallback((data) => {
    const update = async () => {
      try {
        const response = await api.put(`/adm-pass/${id}`, data);
        const { message } = response.data;
        notification.success({
          message: 'Alterar Senha',
          description: message,
        });
        setShowModal(false);
      } catch (error) {
        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notification.error({
              message: 'Falha para Alterar Senha',
              description: message,
            });
            return;
          }
        }

        notification.error({
          message: 'Falha para Alterar Senha',
          description: error,
        });
      }
    };

    update();
  }, [id]);

  return (
    <>
      <Modal
        id={id}
        visible={showModal}
        onUpdate={handleUpdate}
        onClose={() => setShowModal(false)}
      />
      <Table
        columns={columns}
        actions={{
          title: 'Ações',
          key: 'action',
          width: 100,
          align: 'center',
          render: (text, record) => (
            <Space size="middle">
              {props.hasActionUpdate && (
                <Tooltip placement="top" title="Alterar">
                  <Action onClick={() => props.onOpen(record.id || record._id)}>
                    <EditTwoTone />
                  </Action>
                </Tooltip>
              )}
              {props.hasActionDelete && (
                <Tooltip placement="top" title="Excluir">
                  <Action
                    onClick={() => props.onDelete(record.id || record._id)}
                  >
                    <DeleteTwoTone twoToneColor="#f00" />
                  </Action>
                </Tooltip>
              )}
              <Tooltip placement="top" title="Alterar Senha">
                <Action onClick={() => {
                  setId(record.id || record._id);
                  setShowModal(true);
                }}
                >
                  <LockOutlined
                    style={{
                      color: '#fadb14',
                    }}
                  />
                </Action>
              </Tooltip>
            </Space>
          ),
        }}
        {...props}
      />
    </>
  );
}

export default AdmTable;
