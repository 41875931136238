import styled from 'styled-components';

export const DataContainer = styled.div`
  padding: 5px 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
`;

export const Data = styled.span`
  display: block;
  font-size: 10pt;
`;

export const Updated = styled.span`
  display: block;
  font-size: 10pt;
  color: #389e0d;
`;

export const Label = styled.label`
  font-size: 8pt;
  font-weight: 600;
  color: #333;
`;
