import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { MsgError } from '../styles';
import { Container, Input, Label } from './styles';

export default function InputComponent({
  name,
  hidden,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName, defaultValue = '', registerField, error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      hidden={hidden}
    >
      {error && <MsgError>{error}</MsgError>}
      {inputRef && inputRef.current && inputRef.current.value && (
        <Label>{rest.placeholder}</Label>
      )}
      <Input
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </Container>
  );
}
