import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';

import { FileAddOutlined } from '@ant-design/icons';
import { useField } from '@unform/core';

import { MsgError } from '../styles';
import { FileInputContainer } from './styles';

export default function FileInput({ name, label = 'Selecione um Arquivo', ...rest }) {
  const inputRef = useRef(null);

  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);
  const [filename, setFilename] = useState('');

  const handleFileName = useCallback((e) => {
    const file = e.target.files[0];

    if (!file) {
      setFilename(null);
      return;
    }

    setFilename(file.name);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        // eslint-disable-next-line no-param-reassign
        ref.value = '';
        setFilename(null);
      },
      setValue(_ref, value) {
        setFilename(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <FileInputContainer filename={filename} error={!!error}>
      {error && <MsgError>{error}</MsgError>}
      {label && !filename && !error && <small>{label}</small>}
      <FileAddOutlined />
      <input
        type="file"
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={handleFileName}
        hidden
        {...rest}
      />
      {filename && <strong>{filename}</strong>}
    </FileInputContainer>
  );
}
