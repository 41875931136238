import React from 'react';
import { useParams } from 'react-router-dom';

import Table from '../../components/UserByBusinessTeam/Table';
import Page from '../../layout/DefaultManager';

export default function UserByBusinessTeam() {
  const { id, name } = useParams();

  return (
    <Page
      path={`/solicitation-by-business-team/${id}`}
      title={`Business Team ${name}`}
      // subTitle={`Usuários Cadastrados no Business Team ${name}`}
      description={`Usuários Cadastrados no Business Team ${name}`}
      selectedKey="3"
      hasActionCreate={false}
      hasActionUpdate={false}
      hasActionDelete={false}
      Table={Table}
    />
  );
}
