import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Space, Tooltip, notification } from 'antd';

import api from '../../services/api';
import Table from '../Table';
import ColumnSearch from '../Table/Filter';
import { Action } from '../Table/styles';
import ModalBusinessTeam from '../User/ModalBusinessTeam';
import { Counter } from './styles';

function UserByBussinessTeamTable(props) {
  const { id: businessTeamId } = useParams();

  const [showBusinessTeam, setShowBusinessTeam] = useState(false);
  const [userId, setUserId] = useState(null);
  const columns = [
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      width: 150,
      sorter: (a, b) => {
        if (a.cnpj) {
          return a.cnpj.localeCompare(b.cnpj);
        }
        return -1;
      },
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('CNPJ', 'cnpj'),
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
      width: 125,
      sorter: (a, b) => {
        if (a.cpf) {
          return a.cpf.localeCompare(b.cpf);
        }
        return -1;
      },
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('CPF', 'cpf'),
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'fantasy_name',
      key: 'fantasy_name',
      sorter: (a, b) => a.fantasy_name.localeCompare(b.fantasy_name),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Nome Fantasia', 'fantasy_name'),
    },
    {
      title: 'Proprietário 1',
      dataIndex: 'owner_name1',
      key: 'owner_name1',
      sorter: (a, b) => a.owner_name1.localeCompare(b.owner_name1),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Proprietário 1', 'owner_name1'),
    },
    {
      title: 'Email',
      dataIndex: 'contact_email',
      key: 'contact_email',
      sorter: (a, b) => a.contact_email.localeCompare(b.contact_email),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Email', 'contact_email'),
    },
    {
      title: 'Aprovado',
      dataIndex: 'aproved',
      key: 'aproved',
      width: 96,
      align: 'center',
      render: (text, record) => (
        record.aproved ? (
          <Tooltip placement="top" title="Cadastro Aprovado">
            <CheckCircleTwoTone twoToneColor="#389e0d" />
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Cadastro não Aprovado">
            <CloseCircleTwoTone twoToneColor="#f5222d" />
          </Tooltip>
        )
      ),
    },
  ];

  const handleApprove = async (id, aproved) => {
    try {
      const response = await api.put(`/approve/${id}`, aproved);
      const { data, message } = response.data;
      props.onUpdateData(data);
      notification.success({
        message: 'Aprovação de Cadastro',
        description: message,
      });
    } catch (error) {
      if (error && error.response) {
        const { message } = error.response.data;
        if (message) {
          notification.error({
            message: 'Aprovação de Cadastro',
            description: message,
          });
          return;
        }
      }

      notification.error({
        message: 'Aprovação de Cadastro',
        description: error,
      });
    }
  };

  const handleUpadateData = (data) => {
    const { model } = data;
    if (model && model !== undefined) {
      props.onUpdateDatas(props.data.filter(
        (d) => !(d._id === model._id && model.businessTeam !== businessTeamId),
      ));
    }
  };

  return (
    <>
      <ModalBusinessTeam
        id={userId}
        visible={showBusinessTeam}
        onUpdateData={handleUpadateData}
        onClose={() => setShowBusinessTeam(false)}
      />
      <Counter>{`Total de Usuários cadastrado: ${props.data.length}`}</Counter>
      <Table
        columns={columns}
        actions={{
          title: 'Ações',
          key: 'action',
          width: 150,
          render: (text, record) => (
            <Space size="middle">
              {
              record.aproved ? (
                <Tooltip placement="top" title="Desaprovar Cadastro">
                  <Action onClick={() => handleApprove(record.id || record._id, {
                    aproved: false,
                  })}
                  >
                    <CloseCircleTwoTone twoToneColor="#f5222d" />
                  </Action>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Aprovar Cadastro">
                  <Action onClick={() => handleApprove(record.id || record._id, {
                    aproved: true,
                  })}
                  >
                    <CheckCircleTwoTone twoToneColor="#389e0d" />
                  </Action>
                </Tooltip>
              )
            }
              <Tooltip placement="top" title="Business Team">
                <Action onClick={() => {
                  setUserId(record.id || record._id);
                  setShowBusinessTeam(true);
                }}
                >
                  <UsergroupAddOutlined />
                </Action>
              </Tooltip>
            </Space>
          ),
        }}
        {...props}
      />
    </>
  );
}

export default UserByBussinessTeamTable;
