import React, { useRef, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import {
  Modal, Button, Space, notification,
} from 'antd';
import * as Yup from 'yup';

import api from '../../services/api';
import Loading from '../Loading';
import { Content } from './styles';

export default function ModalForm({
  id,
  title,
  path,
  schemaValidation,
  visible,
  children,
  loading,
  setData,
  onCreate,
  onUpdate,
  onClose,
  onFormData = (data) => (data),
  onSetFieldValue,
  onSchemaValidation,
}) {
  const [loadingForm, setLoadingForm] = useState(true);
  const formRef = useRef(null);

  useEffect(() => {
    const loadData = async () => {
      setLoadingForm(true);
      if (formRef.current) {
        formRef.current.setErrors({});
        formRef.current.reset();
      }
      if (id && path) {
        const res = await api.get(`${path}/${id}`);
        const { data } = res;
        formRef.current.setData(data);
        if (onSetFieldValue) {
          onSetFieldValue(formRef, data);
        }
      }

      setLoadingForm(false);
    };

    loadData();
  }, [id, onSetFieldValue, path]);

  useEffect(() => {
    if (setData) { formRef.current.setData(setData); }
  }, [setData]);

  const handleSubmit = async (data, { reset }) => {
    try {
      if (onSchemaValidation) {
        await onSchemaValidation(data);
      } else if (schemaValidation) {
        await schemaValidation.validate(data, {
          abortEarly: false,
        });
      }

      // Validation passed
      const formData = onFormData(data);

      if (id) {
        onUpdate(formData);
      } else {
        onCreate(formData);
      }

      formRef.current.setErrors({});
      reset();
    } catch (err) {
      // Validation failed
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        notification.error({
          message: 'Falha na Validação!!!',
          description: 'Falta preencher algumas informações',
        });
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Loading spinning={loading || loadingForm}>
          <Content>{children}</Content>
          <Space>
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
            <Button onClick={onClose}>Fechar</Button>
          </Space>
        </Loading>
      </Form>
    </Modal>
  );
}
