import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  TeamOutlined,
} from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

import Table from '../Table';
import ColumnSearch from '../Table/Filter';
import { Action } from '../Table/styles';

function UserTable(props) {
  const history = useHistory();
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 96,
      align: 'center',
      render: (text, record) => (
        record.status ? (
          <Tooltip placement="top" title="Ativo">
            <CheckCircleTwoTone twoToneColor="#389e0d" />
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Inativo">
            <CloseCircleTwoTone twoToneColor="#f5222d" />
          </Tooltip>
        )
      ),
    },
    {
      title: 'Célula',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Célula', 'name'),
    },
    {
      title: 'Business Team',
      dataIndex: 'businessTeam',
      key: 'businessTeam',
      render: (text, record) => {
        if (record.businessTeam) {
          return <span>{record.businessTeam.name}</span>;
        }

        return null;
      },
    },
  ];

  return (
    <Table
      columns={columns}
      actions={{
        title: 'Ações',
        key: 'action',
        width: 150,
        render: (text, record) => (
          <Space size="middle">
            <Tooltip placement="top" title="Alterar">
              <Action onClick={() => props.onOpen(record.id || record._id)}>
                <EditTwoTone />
              </Action>
            </Tooltip>
            <Tooltip placement="top" title="Excluir">
              <Action onClick={() => props.onDelete(record.id || record._id)}>
                <DeleteTwoTone twoToneColor="#f00" />
              </Action>
            </Tooltip>
            <Tooltip placement="top" title="Ver Usuários">
              <Action onClick={() => { history.push(`/celulas-usuarios/${(record.id || record._id)}/${record.name}`); }}>
                <TeamOutlined />
              </Action>
            </Tooltip>
          </Space>
        ),
      }}
      {...props}
    />
  );
}

export default UserTable;
