import React from 'react';

import Modal from '../../components/Documents/Modal';
import Table from '../../components/Documents/Table';
import Page from '../../layout/DefaultManager';

export default function Documents() {
  return (
    <Page
      path="/trainings"
      title="Treinamentos"
      subTitle="Gerenciador de Treinamentos"
      // description="Descricao para gerenciador"
      selectedKey="11"
      Table={Table}
      ModalForm={Modal}
    />
  );
}
