import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import {
  Container,
  CheckboxStyle,
  Checkmark,
  Label,
} from './styles';

export default function Checkbox({
  name, label, hidden, ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName, defaultChecked, defaultValue = '', registerField, error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <Container hidden={hidden}>
      <CheckboxStyle
        type="checkbox"
        ref={inputRef}
        defaultChecked={defaultChecked}
        defaultValue={defaultValue}
        error={error}
        {...rest}
      />
      <Checkmark />
      <Label>{label}</Label>
    </Container>
  );
}
