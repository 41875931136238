import React from 'react';

import Modal from '../../components/Notices/Modal';
import Table from '../../components/Table';
import Page from '../../layout/DefaultManager';

export default function Notices() {
  return (
    <Page
      path="/notices"
      title="Noticias"
      subTitle="Gerenciador de Notícias"
      // description="Descricao para gerenciador"
      selectedKey="7"
      Table={Table}
      ModalForm={Modal}
    />
  );
}
