import React from 'react';

import { Modal } from 'antd';

import { Content } from './styles';

export default function ModalComponent({
  title,
  visible,
  onClose,
  children,
  ...props
}) {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      {...props}
    >
      <Content>{children}</Content>
    </Modal>
  );
}
