import React, { useRef, useEffect } from 'react';
import Select from 'react-select';

import { useField } from '@unform/core';

import { MsgError } from '../styles';
import { Container, Label } from './styles';

export default function ReactSelect({
  name,
  label,
  options,
  multiple,
  ...rest
}) {
  const ref = useRef(null);
  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'state.value',
      clearValue: (selectRef) => {
        selectRef.select.clearValue();
      },
      getValue: (selectRef) => {
        const selectValue = selectRef.state.value;
        if (!multiple) {
          if (selectValue && selectValue.value !== undefined) {
            return selectValue ? selectValue.value : '';
          }
          return selectValue;
        }

        return selectValue ? selectValue.map((option) => option.value) : [];
      },
      setValue(selectRef, selected) {
        if (!selected) {
          selectRef.state.value = '';
          return;
        }

        if (selected.value !== undefined) {
          selectRef.state.value = selected;
          return;
        }

        if (options && options !== undefined) {
          const option = options.find((opt) => opt.value === selected);
          selectRef.state.value = {
            value: selected,
            label: option ? option.label : '',
          };
        }
      },
    });
  }, [registerField, options, multiple, fieldName]);

  const getDefaultValue = () => {
    if (!defaultValue) return null;

    if (!multiple) {
      return options.find((option) => option.value === defaultValue);
    }

    return options.filter((option) => defaultValue.includes(option.value));
  };

  return (
    <Container>
      {error && <MsgError>{error}</MsgError>}
      <Label>{label}</Label>
      <Select
        ref={ref}
        name={fieldName}
        aria-label={fieldName}
        placeholder="Selecione"
        noOptionsMessage={() => 'Nenhuma Opção Encontrada'}
        options={options}
        isMulti={multiple}
        defaultValue={getDefaultValue()}
        {...rest}
      />
    </Container>
  );
}
