import React from 'react';

import * as Yup from 'yup';

import Input from '../Form/Input';
import Form from '../Modal/ModalForm';

function Password(props) {
  const schemaValidation = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Informe pelo menos 6 caracteres')
      .required('A senha é obrigatória'),
  });

  return (
    <Form
      title="Alterar Senha"
      schemaValidation={schemaValidation}
      {...props}
    >
      <Input
        type="password"
        name="password"
        placeholder="Senha"
      />
    </Form>
  );
}

export default Password;
