import React from 'react';

import Modal from '../../components/Advertisings/Modal';
import Table from '../../components/Advertisings/Table';
import Page from '../../layout/DefaultManager';

export default function Advertisings() {
  return (
    <Page
      path="/advertisings"
      title="Propagandas"
      subTitle="Gerenciador de Propagandas"
      // description="Descricao para gerenciador"
      selectedKey="10"
      Table={Table}
      ModalForm={Modal}
    />
  );
}
