import React from 'react';

import Modal from '../../components/Documents/Modal';
import Table from '../../components/Documents/Table';
import Page from '../../layout/DefaultManager';

export default function Documents() {
  return (
    <Page
      path="/documents"
      title="Documentos"
      subTitle="Gerenciador de Documentos"
      // description="Descricao para gerenciador"
      selectedKey="5"
      Table={Table}
      ModalForm={Modal}
    />
  );
}
