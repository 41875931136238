import React, { useState, useEffect } from 'react';

import { notification } from 'antd';
import * as Yup from 'yup';

import api from '../../services/api';
import Checkbox from '../Form/Checkbox';
import Input from '../Form/Input';
import Select from '../Form/Select';
import Form from '../Modal/ModalForm';


export default function BusinessTeamCellModal(props) {
  const [businessTeams, setBusinessTeams] = useState([]);
  const schemaValidation = Yup.object().shape({
    // thumbnail: Yup.mixed().required('Selecione uma imagem'),
    name: Yup.string().required('O nome é obrigatório'),
  });

  const handleSetFieldValue = async (formRef, data) => {
    if (data) {
      if (data.businessTeam) {
        await formRef.current.setFieldValue('businessTeam', {
          value: data.businessTeam._id,
          label: data.businessTeam.name,
        });
      }
    }
  };

  useEffect(() => {
    const loadBusinessTeam = async () => {
      try {
        const response = await api.get('business-team');
        const bts = [];
        response.data.forEach((bt) => {
          if (bt.status) {
            bts.push({
              value: bt._id,
              label: bt.name,
            });
          }
        });
        setBusinessTeams(bts);
      } catch (error) {
        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notification.error({
              message: 'Falha ao Carregar Business Team',
              description: message,
            });
            return;
          }
        }

        notification.error({
          message: 'Falha ao Carregar Business Team',
          description: error,
        });
      }
    };

    loadBusinessTeam();
  }, []);

  return (
    <Form
      title="Célula"
      schemaValidation={schemaValidation}
      onSetFieldValue={handleSetFieldValue}
      {...props}
    >
      <Select
        label="Business Team"
        name="businessTeam"
        options={businessTeams}
      />
      <Input
        name="name"
        placeholder="Nome"
      />
      <Checkbox
        name="status"
        label="Ativo"
        hidden={!props.id}
      />
    </Form>
  );
}
