/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';

import { Modal, notification } from 'antd';
import filesize from 'filesize';
import { uniqueId } from 'lodash';

import api from '../../services/api';
import FileList from '../DragDrop/FileList';
import Upload from '../DragDrop/Upload';
import Loading from '../Loading';
import { Container, Content } from './styles';

export default function Photo({
  id,
  title,
  visible,
  onClose,
}) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadPhotos = async () => {
      try {
        if (id) {
          setLoading(true);
          const response = await api.get(`/images/${id}`);
          const loadFiles = response.data.map((file) => ({
            file,
            id: file._id,
            name: file.thumbnail,
            readableSize: file.size,
            preview: encodeURI(file.thumbnail_url),
            progress: 100,
            uploaded: true,
            error: false,
            url: file.thumbnail_url,
          }));

          setUploadedFiles(loadFiles);
          setLoading(false);
        }
      } catch (error) {
        notification.error({
          message: 'Fotos da Galeria',
          description: 'Aconteceu um problema e não foi possivel carregar as fotos da galeria',
        });
      }
    };

    loadPhotos();
  }, [id]);

  const updateFile = (fileId, data) => {
    setUploadedFiles((state) => (
      state.map((uploadedFile) => (
        fileId === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile
      ))
    ));
  };

  const processUpload = (uploadedFile) => {
    const data = new FormData();
    data.append('thumbnail', uploadedFile.file);
    data.append('filename', uploadedFile.name);
    data.append('size', uploadedFile.readableSize);
    data.append('gallery', id);

    api
      .post('/images', data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          updateFile(uploadedFile.id, { progress });
        },
      })
      .then((response) => {
        const { model } = response.data;
        updateFile(uploadedFile.id, {
          uploaded: true,
          id: model._id,
          url: model.thumbnail_url,
        });
      })
      .catch((err) => {
        let errorDetail;
        if (err.response) {
          const { error } = err.response.data;
          const { message } = error;
          errorDetail = message;
        }

        updateFile(uploadedFile.id, {
          error: true,
          errorDetail,
        });
      });
  };

  const handleUpload = (files = []) => {
    const newFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: encodeURI(URL.createObjectURL(file)),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFiles([
      ...newFiles,
      ...uploadedFiles,
    ]);

    newFiles.forEach(processUpload);
  };

  const handleDelete = async (imageId) => {
    try {
      await api.delete(`/images/${imageId}`);
      setUploadedFiles(uploadedFiles.filter((file) => (file.id !== imageId)));
      notification.success({
        message: 'Fotos da Galeria',
        description: 'Foto excluida com sucesso!!!',
      });
    } catch (err) {
      notification.error({
        message: 'Fotos da Galeria',
        description: 'Aconteceu um problema e não foi possivel excluir a foto da galeria',
      });
    }
  };

  return (
    <Modal
      title={`Fotos da Galeria "${title}"`}
      visible={visible}
      okText="Fechar"
      onOk={onClose}
      onCancel={onClose}
      cancelButtonProps={{ hidden: true }}
      width="60%"
      centered
    >
      <Container>
        <Content>
          <Loading spinning={loading}>
            <Upload onUpload={handleUpload} />
            <FileList
              files={uploadedFiles}
              onDelete={handleDelete}
            />
          </Loading>
        </Content>
      </Container>
    </Modal>
  );
}
