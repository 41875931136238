import React from 'react';

import * as Yup from 'yup';

import Checkbox from '../Form/Checkbox';
import ImageInput from '../Form/ImageInput';
import Input from '../Form/Input';
import Form from '../Modal/ModalForm';

export default function AdvertisingModal(props) {
  const handleSchemaValidation = async (data) => {
    const schemaValidation = Yup.object().shape({
      thumbnail: Yup.mixed().test({
        name: 'thumbnail',
        message: 'Selecione uma Imagem',
        test: () => props.id || (!props.id && !!data.thumbnail),
      }),
    });

    const validate = await schemaValidation.validate(data, {
      abortEarly: false,
    });
    return validate;
  };

  const handleFormData = (data) => {
    const formData = new FormData();
    formData.append('thumbnail', data.thumbnail);
    formData.append('name', data.name);
    formData.append('link', data.link);
    formData.append('start', data.start);
    formData.append('associate', data.associate);
    formData.append('signin', data.signin);
    formData.append('timer', data.timer);
    formData.append('home', data.home);
    return formData;
  };

  return (
    <Form
      title="Propagandas"
      onSchemaValidation={handleSchemaValidation}
      onFormData={handleFormData}
      {...props}
    >
      <ImageInput name="thumbnail" />
      <Input name="name" placeholder="Nome" />
      <Input name="link" placeholder="Endereço URL da propaganda" />
      <Checkbox name="start" label="Exibir no Início  (Ideal Paisagem)" />
      <Checkbox name="associate" label="Exibir em Parceiros (Ideal Retrato)" />
      <Checkbox name="signin" label="Exibir no Login (Ideal Paisagem)" />
      <Checkbox name="timer" label="Exibir no Timer (Ideal Retrato)" />
      <Checkbox name="home" label="Exibir na Home (Ideal Paisagem)" />
    </Form>
  );
}
