import React, { useState, useEffect, useCallback } from 'react';

// import * as Yup from 'yup';
import { Tabs, notification } from 'antd';

import categories from '../../datas/categorias.json';
import api from '../../services/api';
import Checkbox from '../Form/Checkbox';
import Input from '../Form/Input';
import InputMask from '../Form/InputMask';
import Select from '../Form/Select';
import Textarea from '../Form/Textarea';
import Form from '../Modal/ModalForm';
import { BusinessTeamContainer } from './styles';

export default function UserModal(props) {
  const [isCNPJ, setIsCNPJ] = useState(null);
  const [businessTeams, setBusinessTeams] = useState([]);
  const [businessTeamCells, setBusinessTeamCells] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formRef, setFormRef] = useState(true);

  const loadBusinessTeamCells = useCallback((btId) => {
    const load = async () => {
      try {
        if (btId) {
          setLoading(true);
          formRef.current.setFieldValue('businessTeamCell', null);
          const response = await api.get(
            `/business-team-cell/business-team/${btId}`,
          );
          const btcs = [];
          response.data.forEach((btc) => {
            if (btc.status) {
              btcs.push({
                value: btc._id,
                label: btc.name,
              });
            }
          });

          setBusinessTeamCells(btcs);
          setLoading(false);
        }
      } catch (error) {
        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notification.error({
              message: 'Falha ao Carregar as células do Business Team',
              description: message,
            });
            return;
          }
        }

        notification.error({
          message: 'Falha ao Carregar as células do Business Team',
          description: error,
        });
      }
    };

    load();
  }, [formRef]);

  useEffect(() => {
    const loadBusinessTeam = async () => {
      try {
        const response = await api.get('business-team');
        const bts = [];
        response.data.forEach((bt) => {
          if (bt.status) {
            bts.push({
              value: bt._id,
              label: bt.name,
            });
          }
        });
        setBusinessTeams(bts);
      } catch (error) {
        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notification.error({
              message: 'Falha ao Carregar Business Team',
              description: message,
            });
            return;
          }
        }

        notification.error({
          message: 'Falha ao Carregar Business Team',
          description: error,
        });
      }
    };

    loadBusinessTeam();
  }, []);

  const handleSetFieldValue = useCallback((fRef, data) => {
    const load = async () => {
      if (data) {
        if (data.businessTeam) {
          const { _id, name } = data.businessTeam;
          fRef.current.setFieldValue('businessTeam', {
            value: _id,
            label: name,
          });

          if (_id && data.businessTeamCell) {
            fRef.current.setFieldValue('businessTeamCell', {
              value: data.businessTeamCell._id,
              label: data.businessTeamCell.name,
            });
          }
        }
        setFormRef(fRef);
        setIsCNPJ(!!data.cnpj);
      }
    };

    load();
  }, []);

  const handleChangeBusinessTeam = useCallback((bt) => {
    const load = async () => {
      if (bt) {
        await loadBusinessTeamCells(bt.value);
      }
    };

    load();
  }, [loadBusinessTeamCells]);

  return (
    <Form
      title="Usuário"
      loading={loading}
      onSetFieldValue={handleSetFieldValue}
      {...props}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Empresa" key="1">
          {/*
            cnpj: String,
            cpf: String,
            fantasy_name: String,
            date_open: Date,
            activity_branch: String,
            owner_name1: String,
            owner_name2: String,
            owner_name3: String,
            owner_name4: String,
            owner_name5: String,
            small_description: String,
          */}
          <Input name="contact_name" placeholder="Nome Completo" />
          <Input type="cnpj" name="cnpj" placeholder="CNPJ" hidden={!isCNPJ} />
          <Input type="cpf" name="cpf" placeholder="CPF" hidden={isCNPJ} />

          <Input name="fantasy_name" placeholder="Nome Fantasia" />
          <InputMask
            type="date"
            name="date_open"
            placeholder="Inicio das Atividades"
          />
          <Select
            name="activity_branch"
            label="Ramo de Atividade"
            options={categories.filter(
              (option) => option.value !== null && option.value !== '',
            )}
          />
          <Input name="owner_name1" placeholder="Proprietário 1" />
          <Input name="owner_name2" placeholder="Proprietário 2" />
          <Input name="owner_name3" placeholder="Proprietário 3" />
          <Input name="owner_name4" placeholder="Proprietário 4" />
          <Input name="owner_name5" placeholder="Proprietário 5" />
          <Textarea name="small_description" placeholder="Descrição" />
          <Checkbox name="aproved" label="Aprovado" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Endereço" key="2" forceRender>
          {/*
            zip_code: String,
            street: String,
            number: String,
            complement: String,
            neighborhood: String,
            city: String,
            state: String,
          */}
          <Input name="zip_code" placeholder="CEP" />
          <Input name="street" placeholder="Logradouro" />
          <Input name="number" placeholder="Número" />
          <Input name="complement" placeholder="Complemento" />
          <Input name="neighborhood" placeholder="Bairro" />
          <Input name="city" placeholder="Cidade" />
          <Input name="state" placeholder="Estado" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Contatos" key="3" forceRender>
          {/**
            phone: String,
            whatsapp: String,
            other_phones: String,
            website: String,
            facebook: String,
            instagram: String,
            company_email: String,
            contact_name: String,
            contact_email: String,
            password: String,
           */}
          <Input name="whatsapp" placeholder="WhatsApp" />
          <Input name="phone" placeholder="Telefone" />
          <Input name="other_phones" placeholder="Outros Telefones" />
          <Input
            type="email"
            name="company_email"
            placeholder="Email Empresarial"
          />
          <Input
            type="email"
            name="contact_email"
            placeholder="Email do Contato/Login"
          />
          <Input name="website" placeholder="Site" />
          <Input name="facebook" placeholder="Facebook" />
          <Input name="instagram" placeholder="Instagram" />
          <Input
            name="password"
            type="password"
            placeholder="Senha"
            autoComplete="off"
            hidden={props.id}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Business Team e Célula" key="4" forceRender>
          <BusinessTeamContainer>
            <Select
              label="Business Team"
              name="businessTeam"
              options={businessTeams}
              onChange={handleChangeBusinessTeam}
            />

            <Select
              label="Célula"
              name="businessTeamCell"
              options={businessTeamCells}
            />
          </BusinessTeamContainer>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
}
