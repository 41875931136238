import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #fff;
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 50px;
  width: 400px;
  min-height: 200px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #1890ff;
  img{
    height: 200px;
    margin-bottom: 30px;
    align-self: center;
  }
`;
