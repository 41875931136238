import React from 'react';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  FileSearchOutlined,
} from '@ant-design/icons';
import { Space, Tooltip, notification } from 'antd';

import api from '../../services/api';
import Table from '../Table';
import ColumnSearch from '../Table/Filter';
import { Action } from '../Table/styles';

function UserTable({
  onOpen,
  onDelete,
  onUpdateData,
  ...props
}) {
  const columns = [
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      width: 150,
      sorter: (a, b) => {
        if (a.cnpj) {
          return a.cnpj.localeCompare(b.cnpj);
        }
        return -1;
      },
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('CNPJ', 'cnpj'),
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
      width: 125,
      sorter: (a, b) => {
        if (a.cpf) {
          return a.cpf.localeCompare(b.cpf);
        }
        return -1;
      },
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('CPF', 'cpf'),
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'fantasy_name',
      key: 'fantasy_name',
      sorter: (a, b) => a.fantasy_name.localeCompare(b.fantasy_name),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Nome Fantasia', 'fantasy_name'),
    },
    {
      title: 'Proprietário 1',
      dataIndex: 'owner_name1',
      key: 'owner_name1',
      sorter: (a, b) => a.owner_name1.localeCompare(b.owner_name1),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
      filterMultiple: false,
      ...ColumnSearch('Proprietário 1', 'owner_name1'),
    },
    {
      title: 'Aprovado',
      dataIndex: 'aproved',
      key: 'aproved',
      width: 96,
      align: 'center',
      render: (text, record) => (
        record.aproved ? (
          <Tooltip placement="top" title="Alteração Aprovada">
            <CheckCircleTwoTone twoToneColor="#389e0d" />
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Aguardando Aprovação">
            <CloseCircleTwoTone twoToneColor="#f5222d" />
          </Tooltip>
        )
      ),
    },
  ];

  const handleApprove = async (id, aproved) => {
    try {
      const response = await api.put(`/approve-profile/${id}`, aproved);
      const { data, message } = response.data;
      onUpdateData(data);
      notification.success({
        message: 'Alteração de Cadastro',
        description: message,
      });
    } catch (error) {
      if (error && error.response) {
        const { message } = error.response.data;
        if (message) {
          notification.error({
            message: 'Alteração de Cadastro',
            description: message,
          });
          return;
        }
      }

      notification.error({
        message: 'Alteração de Cadastro',
        description: error,
      });
    }
  };

  return (
    <Table
      columns={columns}
      actions={{
        title: 'Ações',
        key: 'action',
        width: 150,
        render: (text, record) => (
          <Space size="middle">
            <Tooltip placement="top" title="Visualizar">
              <Action onClick={() => onOpen(record.id || record._id)}>
                <FileSearchOutlined />
              </Action>
            </Tooltip>
            <Tooltip placement="top" title="Aprovar Alteração">
              <Action onClick={() => handleApprove(record.id || record._id, {
                aproved: true,
              })}
              >
                <CheckCircleTwoTone twoToneColor="#389e0d" />
              </Action>
            </Tooltip>
          </Space>
        ),
      }}
      {...props}
    />
  );
}

export default UserTable;
