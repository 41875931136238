import React from 'react';

import { Result, Button } from 'antd';

export default function NotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Desculpe, a página que você esta visitando não existe."
      extra={<Button type="primary" href="/home">Home</Button>}
    />
  );
}
